@mixin transition-all() {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
@mixin serif() {
  font-family: 'Lora', 'Times New Roman', serif;
}
@mixin sans-serif() {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

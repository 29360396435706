@import "common/variables";
@import "common/mixins";

.ds-post-preview {
  a {
    color: $gray-dark;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $brand-primary;
    }
    .ds-post-title {
      font-size: 30px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    .ds-post-subtitle {
      margin: 0;
      font-weight: 300;
      margin-bottom: 10px;
    }
    .ds-post-meta {
      color: $gray;
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 40px;
    }
  }
  @media only screen and (min-width: 768px) {
    a {
      .ds-post-title {
        font-size: 36px;
      }
      // Pager Styling
    }
  }
}
.pager {
  margin: 20px 0 0;
  li {
    a,
    span {
      @include sans-serif();
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 800;
      letter-spacing: 1px;
      padding: 15px 25px;
      background-color: white;
      border-radius: 0;
    }
    a:hover,
    a:focus {
      color: white;
      background-color: $brand-primary;
      border: 1px solid $brand-primary;
    }
  }
  .disabled {
    a,
    a:hover,
    a:focus,
    span {
      color: $gray;
      background-color: $gray-dark;
      cursor: not-allowed;
    }
  }
}
